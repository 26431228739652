export const chains = [
  { name: 'Ethereum', chain: 'ethereum', symbol: 'ETH', img: 'eth.png' },
  // { name: 'Binance', chain: 'binance', symbol: 'BNB', img: 'bnb.png' },
  { name: 'BSC', chain: 'binance', symbol: 'BNB', img: 'bnb.png' },
  { name: 'Tron', chain: 'tron', symbol: 'TRX', img: 'trx.png' },
  { name: 'Arbitrum', chain: 'arbitrum', symbol: 'ARB', img: 'arb.png' },
  { name: 'Optimism', chain: 'optimism', symbol: 'OP', img: 'op.png' },
  { name: 'Avalanche', chain: 'avalanche', symbol: 'AVAX', img: 'avax.png' },
  { name: 'Fantom', chain: 'fantom', symbol: 'FTM', img: 'ftm.png' },
  { name: 'Polygon', chain: 'polygon', symbol: 'MATIC', img: 'matic.png' },
  { name: 'zkSync', chain: 'zkSync', symbol: 'ZKSYNC', img: 'zkSync.png' },
  { name: 'Cronos', chain: 'cronos', symbol: 'CRO', img: 'cro.png' },
  { name: 'Canto', chain: 'canto', symbol: 'CANTO', img: 'canto.png' },
  { name: 'Celo', chain: 'celo', symbol: 'CELO', img: 'celo.png' },
  { name: 'Harmony', chain: 'harmony', symbol: 'ONE', img: 'one.png' },
  { name: 'Auora', chain: 'auora', symbol: 'AUR', img: 'aur.png' },
  { name: 'Arbitrum Nova', chain: 'nova', symbol: 'NOVA', img: 'nova.png' },
  { name: 'Moonbeam', chain: 'moonbeam', symbol: 'GLMR', img: 'glmr.png' },
  { name: 'Moonriver', chain: 'moonriver', symbol: 'MOVR', img: 'movr.png' },
  { name: 'Evmos', chain: 'evmos', symbol: 'EVMOS', img: 'evmos.png' },
  { name: 'Heco', chain: 'heco', symbol: 'HT', img: 'ht.png' },
  { name: 'Metis', chain: 'metis', symbol: 'METIS', img: 'metis.png' },
  { name: 'xDai', chain: 'xdai', symbol: 'XDAI', img: 'xdai.png' },
  { name: 'Astar', chain: 'astar', symbol: 'ASTAR', img: 'astar.png' },
  { name: 'Oasis Emerald', chain: 'oasis emerald', symbol: 'OASIS', img: 'oasis.png' },
  { name: 'Avalanche DFK', chain: 'dfk', symbol: 'DFK', img: 'dfk.png' },
  { name: 'Boba', chain: 'boba', symbol: 'BOBA', img: 'boba.png' },
  { name: 'ioTex', chain: 'iotex', symbol: 'IOTX', img: 'iotx.png' },
  { name: 'Oec', chain: 'oec', symbol: 'OKT', img: 'okt.png' },
  { name: 'Wanchain', chain: 'wanchain', symbol: 'WAN', img: 'wan.png' },
  { name: 'KuCoin Community Chain', chain: 'kcc', symbol: 'KCS', img: 'kcs.png' },
  { name: 'Velas', chain: 'velas', symbol: 'VLX', img: 'vlx.png' },
  { name: 'Smartbch', chain: 'smartbch', symbol: 'BCH', img: 'bch.png' },
  { name: 'Kardia', chain: 'kardia', symbol: 'KAI', img: 'kai.png' },
  { name: 'Fuse', chain: 'fuse', symbol: 'FUSE', img: 'fuse.png' },
  { name: 'Elastos', chain: 'elastos', symbol: 'ELA', img: 'ela.png' },
  { name: 'Milkomeda', chain: 'milkomeda', symbol: 'MILK', img: 'milk.png' },
  { name: 'Conflux', chain: 'conflux', symbol: 'CFX', img: 'cfx.png' },
  { name: 'Echelon', chain: 'echelon', symbol: 'ECH', img: 'ech.png' },
  { name: 'Polis', chain: 'polis', symbol: 'POLIS', img: 'polis.png' },
  { name: 'ZYX', chain: 'zyx', symbol: 'ZYX', img: 'zyx.png' },
  { name: 'Hoo Smart Chain', chain: 'hoo Smart Chain', symbol: 'HOO', img: 'hoo.png' },
  { name: 'Syscoin', chain: 'syscoin', symbol: 'SYS', img: 'sys.png' },
  { name: 'Klaytn', chain: 'klaytn', symbol: 'KLAY', img: 'klay.png' },
  { name: 'Shiden', chain: 'shiden', symbol: 'SDN', img: 'sdn.png' },
  { name: 'Telos', chain: 'telos', symbol: 'TLOS', img: 'tlos.png' },
  { name: 'Energy Web', chain: 'energyweb', symbol: 'ENERGY', img: 'energy.png' },
  { name: 'Callisto', chain: 'callisto', symbol: 'CLO', img: 'clo.png' },
  { name: 'Solana', chain: 'solana', symbol: 'SOL', img: 'sol.png' },
];

export const chainImages = {
  "bitcoin": 'btc.png',
  "ethereum": "eth.png",
  "binance": "bnb.png",
  "bsc": "bnb.png",
  "tron": "trx.png",
  "arbitrum": "arb.png",
  "optimism": "op.png",
  "avalanche": "avax.png",
  "fantom": "ftm.png",
  "polygon": "matic.png",
  "zkSync": "zkSync.png",
  "cronos": "cro.png",
  "canto": "canto.png",
  "celo": "celo.png",
  "harmony": "one.png",
  "auora": "aur.png",
  "nova": "nova.png",
  "moonbeam": "glmr.png",
  "moonriver": "movr.png",
  "evmos": "evmos.png",
  "heco": "ht.png",
  "metis": "metis.png",
  "xdai": "xdai.png",
  "astar": "astar.png",
  "oasis emerald": "oasis.png",
  "dfk": "dfk.png",
  "boba": "boba.png",
  "iotex": "iotx.png",
  "oec": "okt.png",
  "wanchain": "wan.png",
  "kcc": "kcs.png",
  "velas": "vlx.png",
  "smartbch": "bch.png",
  "kardia": "kai.png",
  "fuse": "fuse.png",
  "elastos": "ela.png",
  "milkomeda": "milk.png",
  "conflux": "cfx.png",
  "echelon": "ech.png",
  "polis": "polis.png",
  "zyx": "zyx.png",
  "hoo Smart Chain": "hoo.png",
  "syscoin": "sys.png",
  "shiden": "sdn.png",
  "telos": "tlos.png",
  "energyweb": "energy.png",
  "callisto": "clo.png",
  "solana": "sol.png",
  "algorand": "algo.png",
  "polkadot": 'dot.png',
  "eos": 'eos.png',
  "zilliqa": 'zil.png',
  "cosmos": 'atom.png',
  "tezos": 'xtz.png',
  "cardano": 'ada.png',
  "aptos": 'apt.png',
  "near": 'near.png',
  "elrond": 'erd.png',
  "ethereumclassic": 'etc.png',
  "litecoin": 'ltc.png',
  "immutable": "_imx.png"
}