import React from "react";
import Layout from "../../../components/Layout";

const DeFiFee = () => {
  return (
    <Layout>
    </Layout>
  );
};

export default DeFiFee;
